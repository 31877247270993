import {
  ContractRequirementStatus,
  ContractRequirementType,
  ContractState,
} from '@paid-ui/enums/contract';
import { PartyLinkingStatus, PayType } from '@paid-ui/enums/party';
import { ProjectIndustry } from '@paid-ui/enums/project';
import { ContractAction } from '@paid-ui/enums/requiring-action';
import { SupercontractCode } from '@paid-ui/enums/supercontract';
import { featureManager } from '@paid-ui/models/feature';
import { projectManager } from '@paid-ui/models/project';
import { userManager } from '@paid-ui/models/user';
import { userGroupManager } from '@paid-ui/models/user-group';
import dayjs from 'dayjs';

import { contractManager } from '../model';

export const savePeriodicPermissions = () => {
  const { disableContractActions } = userManager;
  const {
    templateCode,
    contractState,
    hasAllSigned,
    commencementDate,
    actualCompletionDate,
    currentParty,
    lastReviewParty,
    isPayer,
    isPayee,
    isInvitee,
    inviteeParty,
    isInviter,
    retainableInfo,
    requirements,
    account,
    statuses: {
      isPrimeContract,
      isOffPlatformContract,
      isContractExecuted,
      isDraftContract,
      isSuperContract,
    },
  } = contractManager;
  const { permission } = userGroupManager;
  const {
    canManageContract,
    canSignContract: canUserSignContract,
    canViewContract: canUserViewContract,
  } = permission;

  const { enableSecureContract } = featureManager;
  const { projectIndustry } = projectManager;

  const canSecureContractLegacy = requirements.some(
    (requirement) =>
      requirement.type === ContractRequirementType.LEGACY_SECURE_FUNDS_AGREEMENT &&
      requirement.status === ContractRequirementStatus.FULFILED,
  );
  const canSecureContractModern = requirements.some(
    (requirement) =>
      requirement.type === ContractRequirementType.SECURE_PAID_DEEDS &&
      requirement.status === ContractRequirementStatus.FULFILED,
  );

  const canSecureContract =
    enableSecureContract &&
    !disableContractActions &&
    permission.canManagePayment &&
    (isPayer || isPayee) &&
    (canSecureContractLegacy || canSecureContractModern);

  const isContractSubmitted = contractState === ContractState.SUBMITTED && !isDraftContract;

  const isCommencementDateBeforeToday =
    !!commencementDate && dayjs(commencementDate).isBefore(dayjs().startOf('day'));

  const canAbandonContract =
    !disableContractActions &&
    canManageContract &&
    isInviter &&
    !isPrimeContract &&
    !isDraftContract &&
    [ContractState.SUBMITTED, ContractState.RESUBMITTED, ContractState.REJECTED].includes(
      contractState,
    );

  const canTerminateContract =
    !disableContractActions &&
    canManageContract &&
    !isDraftContract &&
    templateCode === SupercontractCode.OFF_PLATFORM_HEAD_DEPOSIT_CONTRACT_2025 &&
    [ContractState.EXECUTED, ContractState.ALL_PAID].includes(contractState);

  const canDepositRefund =
    !disableContractActions &&
    canManageContract &&
    !isDraftContract &&
    templateCode === SupercontractCode.OFF_PLATFORM_HEAD_DEPOSIT_CONTRACT_2025 &&
    currentParty?.payType === PayType.PAYER &&
    [ContractState.EXECUTED, ContractState.ALL_PAID].includes(contractState) &&
    Number(account.balance?.available) > 0;

  const canCreateClaim = false;
  const canSubmitClaim =
    canManageContract &&
    !!currentParty?.role?.actions &&
    currentParty?.role?.actions.includes(ContractAction.CLAIM_SUBMIT);
  const canEditClaim =
    canManageContract &&
    !!currentParty?.role?.actions &&
    currentParty?.role?.actions.includes(ContractAction.CLAIM_EDIT);
  const canReviewClaim =
    canManageContract &&
    !!currentParty?.role?.actions &&
    currentParty?.role?.actions.includes(ContractAction.CLAIM_REVIEW);
  const canAssessClaim =
    canManageContract &&
    !!currentParty?.role?.actions &&
    currentParty?.role?.actions.includes(ContractAction.CLAIM_ASSESSED);

  const canClaimRetention =
    !disableContractActions &&
    canManageContract &&
    isPayee &&
    isContractExecuted &&
    !!retainableInfo?.claimable?.excludeGstAmount;

  const actions = currentParty?.role?.actions ?? [];
  const disableVariation = requirements.some(
    (requirement) => requirement.type === ContractRequirementType.DISABLED_VARIATION,
  );

  const canCreateVariation =
    !disableContractActions &&
    canManageContract &&
    !disableVariation &&
    actions.includes(ContractAction.VARIATION_SUBMIT) &&
    !isDraftContract &&
    isContractExecuted &&
    hasAllSigned;

  const canResendInvitation = isPrimeContract
    ? !disableContractActions &&
      canManageContract &&
      isContractSubmitted &&
      isInviter &&
      inviteeParty?.linkingStatus === PartyLinkingStatus.UNLINKED
    : !disableContractActions &&
      canManageContract &&
      isContractSubmitted &&
      isInviter &&
      inviteeParty?.linkingStatus === PartyLinkingStatus.LINKED;

  const canReviewOnPlatformContract =
    !disableContractActions &&
    canManageContract &&
    !isOffPlatformContract &&
    !!currentParty &&
    isInvitee;

  const canReviewOffPlatformContract =
    !disableContractActions &&
    canManageContract &&
    isOffPlatformContract &&
    !!currentParty &&
    currentParty.id !== lastReviewParty?.id;

  const canReviewContract =
    !disableContractActions &&
    canManageContract &&
    (canReviewOnPlatformContract || canReviewOffPlatformContract) &&
    [ContractState.SUBMITTED, ContractState.RESUBMITTED].includes(contractState);

  const canSendCommencementNotice =
    projectIndustry !== ProjectIndustry.REAL_ESTATE &&
    !disableContractActions &&
    !isDraftContract &&
    canManageContract &&
    isPayee &&
    isContractExecuted &&
    !commencementDate;

  const canSendCompletionNotice =
    projectIndustry !== ProjectIndustry.REAL_ESTATE &&
    !disableContractActions &&
    !isDraftContract &&
    canManageContract &&
    isPayee &&
    isContractExecuted &&
    !actualCompletionDate &&
    isCommencementDateBeforeToday;

  const canSignContract =
    !disableContractActions &&
    canUserSignContract &&
    isPrimeContract &&
    !isOffPlatformContract &&
    contractState === ContractState.ACCEPTED &&
    currentParty?.linkingStatus !== PartyLinkingStatus.SIGNED;

  const canViewContract = canUserViewContract && !isDraftContract;
  const canEditDraftContract = !disableContractActions && isDraftContract;
  const canDeleteDraftContract = !disableContractActions && isDraftContract;

  const isSecurePaidDeed = templateCode === SupercontractCode.PAID_DEED_SECUREPAID_2025;

  const canRevokeSuperContract =
    !disableContractActions &&
    isSuperContract &&
    !isSecurePaidDeed &&
    contractState === ContractState.ACCEPTED &&
    !hasAllSigned;

  contractManager.permissions = {
    canSecurePayment: canSecureContractLegacy,
    canSecureContract,
    canAbandonContract,
    canTerminateContract,
    canCreateClaim,
    canAssessClaim,
    canEditClaim,
    canSubmitClaim,
    canReviewClaim,
    canCreateVariation,
    canResendInvitation,
    canReviewContract,
    canSendCommencementNotice,
    canSendCompletionNotice,
    canSignContract,
    canViewContract,
    canClaimRetention,
    canRevokeContract: canRevokeSuperContract,
    canEditDraftContract,
    canDeleteDraftContract,
    canDepositRefund,
  };
};
