import { contractRequiringActionSet } from '@paid-ui/constants';
import { CountryCode } from '@paid-ui/enums/address';
import { RequiringActionFeatureType } from '@paid-ui/enums/requiring-action';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { proxy } from 'valtio';
import { devtools, subscribeKey } from 'valtio/utils';

import { paymentRequiringActionFeatureTypeSet } from '../payment';
import { projectManager } from '../project';
import { variationRequiringActionFeatureTypeSet } from './actions/saveWorkItems';
import { initialState } from './initialState';

export const contractManager = proxy(initialState);

devtools(contractManager, {
  name: 'Current Contract',
  enabled: false,
});

subscribeKey(projectManager, 'requiringActions', (value) => {
  const { id, payments, variationWorkItems } = contractManager;
  if (!id) return;

  contractManager.requiringActions = value.filter(
    ({ featureItemId, featureItemType, data }) =>
      contractRequiringActionSet.has(featureItemType) &&
      (featureItemId === id || data?.contractId === id),
  );

  contractManager.payments = payments.map((payment) => ({
    ...payment,
    requiringActions: value.filter(
      ({ featureItemType, data }) =>
        paymentRequiringActionFeatureTypeSet.has(featureItemType) &&
        data?.progressPaymentId &&
        data.progressPaymentId === payment.id,
    ),
  }));

  contractManager.variationWorkItems = variationWorkItems.map((item) => ({
    ...item,
    requiringActions: value.filter(
      ({ featureItemId, featureItemType }) =>
        variationRequiringActionFeatureTypeSet.has(featureItemType) && item.id === featureItemId,
    ),
  }));
});

subscribeKey(contractManager, 'formattedAddress', async (address) => {
  if (!address) {
    contractManager.center = initialState.center;
    contractManager.zoom = initialState.zoom;
    return;
  }

  const [geoCode] = await getGeocode({
    language: 'en-AU',
    region: CountryCode.AU,
    address,
  });

  if (!geoCode) {
    contractManager.center = initialState.center;
    contractManager.zoom = initialState.zoom;
    return;
  }

  const position = getLatLng(geoCode);
  contractManager.center = position;
  contractManager.zoom = 15;
});
