import { contractRequiringActionSet } from '@paid-ui/constants';
import { projectManager } from '@paid-ui/models/project';

import { contractManager } from '../model';

export const saveRequiringActions = (contractId: string) => {
  const { requiringActions } = projectManager;
  contractManager.requiringActions = requiringActions.filter(
    (action) =>
      contractRequiringActionSet.has(action.featureItemType) &&
      (action.featureItemId === contractId || action.data?.contractId === contractId),
  );
};
