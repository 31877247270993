import { type AccountBalance } from '@paid-ui/queries/get-contract-account';

import { initialState } from '../initialState';
import { contractManager } from '../model';

export const saveContractAccount = (accountId: string, balance?: AccountBalance) => {
  const { permissions } = contractManager;
  contractManager.account.id = accountId;
  contractManager.account.balance = balance ?? initialState.account.balance;
  contractManager.permissions.canDepositRefund =
    permissions.canDepositRefund && Number(balance?.available) > 0;
};
