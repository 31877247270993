export enum SupercontractBrand {
  ABIC = 'ABIC',
  AIA = 'AIA',
  MBA = 'MBA',
  PAID = 'PAID',
  RBN = 'RBN',
  OBRE = 'OBRE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum SupercontractCode {
  ABIC_HEAD_SWH_NT_2018 = 'ABIC_HEAD_SWH_NT_2018',
  ABIC_HEAD_SWH_SA_2018 = 'ABIC_HEAD_SWH_SA_2018',
  ABIC_HEAD_SWH_WA_2018 = 'ABIC_HEAD_SWH_WA_2018',
  ABIC_HEAD_SWH_ACT_2018 = 'ABIC_HEAD_SWH_ACT_2018',
  ABIC_HEAD_SWH_TAS_2020 = 'ABIC_HEAD_SWH_TAS_2020',
  ABIC_HEAD_SWH_NSW_2018 = 'ABIC_HEAD_SWH_NSW_2018',
  ABIC_HEAD_SWH_VIC_2018 = 'ABIC_HEAD_SWH_VIC_2018',
  ABIC_HEAD_SWH_VIC_P_2018 = 'ABIC_HEAD_SWH_VIC_P_2018',
  ABIC_HEAD_SWH_VIC_S_2018 = 'ABIC_HEAD_SWH_VIC_S_2018',
  ABIC_HEAD_MWH_NT_2018 = 'ABIC_HEAD_MWH_NT_2018',
  ABIC_HEAD_MWH_SA_2018 = 'ABIC_HEAD_MWH_SA_2018',
  ABIC_HEAD_MWH_WA_2018 = 'ABIC_HEAD_MWH_WA_2018',
  ABIC_HEAD_MWH_ACT_2020 = 'ABIC_HEAD_MWH_ACT_2020',
  ABIC_HEAD_MWH_TAS_2020 = 'ABIC_HEAD_MWH_TAS_2020',
  ABIC_HEAD_MWH_NSW_2018 = 'ABIC_HEAD_MWH_NSW_2018',
  ABIC_HEAD_MWH_VIC_2018 = 'ABIC_HEAD_MWH_VIC_2018',
  ABIC_HEAD_MWH_VIC_P_2018 = 'ABIC_HEAD_MWH_VIC_P_2018',
  ABIC_HEAD_MWH_VIC_S_2018 = 'ABIC_HEAD_MWH_VIC_S_2018',
  ABIC_HEAD_BWC_2018 = 'ABIC_HEAD_BWC_2018',
  ABIC_HEAD_CP_2014 = 'ABIC_HEAD_CP_2014',
  ABIC_HEAD_MW_2018 = 'ABIC_HEAD_MW_2018',
  ABIC_HEAD_SW_2018 = 'ABIC_HEAD_SW_2018',
  ABIC_SUB_BWC_2018 = 'ABIC_SUB_BWC_2018',
  ABIC_SUB_MW_2018 = 'ABIC_SUB_MW_2018',
  ABIC_SUB_SW_2018 = 'ABIC_SUB_SW_2018',
  MBA_DECON_HEAD_2024 = 'MBA_DECON_HEAD_2024',
  MBA_DECON_SUB_2024 = 'MBA_DECON_SUB_2024',
  RBN_HEAD_2024 = 'RBN_HEAD_2024',
  RBN_HIBC_HEAD_2024 = 'RBN_HIBC_HEAD_2024',
  PAID_NHR_HEAD_NSW_2024 = 'PAID_NHR_HEAD_NSW_2024',
  PAID_NHR_HEAD_QLD_2024 = 'PAID_NHR_HEAD_QLD_2024',
  PAID_NHR_HEAD_SA_2024 = 'PAID_NHR_HEAD_SA_2024',
  PAID_NHR_HEAD_TAS_2024 = 'PAID_NHR_HEAD_TAS_2024',
  PAID_NHR_HEAD_VIC_2024 = 'PAID_NHR_HEAD_VIC_2024',
  PAID_NHR_HEAD_WA_2024 = 'PAID_NHR_HEAD_WA_2024',
  PAID_SUB_2024 = 'PAID_SUB_2024',
  PAID_SUPPLY_2024 = 'PAID_SUPPLY_2024',
  AIA_CAA_2019 = 'AIA_CAA_2019',
  OBRE_AA_ES_CONTRACT_2024 = 'OBRE_AA_ES_CONTRACT_2024',
  OBRE_AA_GS_CONTRACT_2024 = 'OBRE_AA_GS_CONTRACT_2024',
  OBRE_AA_EA_CONTRACT_2024 = 'OBRE_AA_EA_CONTRACT_2024',
  OFF_PLATFORM_HEAD_PERIODIC_2024 = 'OFF_PLATFORM_HEAD_PERIODIC_2024',
  OFF_PLATFORM_HEAD_STAGED_2024 = 'OFF_PLATFORM_HEAD_STAGED_2024',
  OFF_PLATFORM_HEAD_DEPOSIT_CONTRACT_2025 = 'OFF_PLATFORM_HEAD_DEPOSIT_CONTRACT_2025',
  OFF_PLATFORM_SUB_PERIODIC_2024 = 'OFF_PLATFORM_SUB_PERIODIC_2024',
  OFF_PLATFORM_SUPPLY_PERIODIC_2024 = 'OFF_PLATFORM_SUPPLY_PERIODIC_2024',
  PAID_DEED_SECUREPAID_2025 = 'PAID_DEED_SECUREPAID_2025',
}

export enum ReferenceToTitleType {
  TORRENS_TITLE = 'TORRENS_TITLE',
  COMPUTER_FOLIO = 'COMPUTER_FOLIO',
  OLD_SYSTEM = 'OLD_SYSTEM',
}

export const ReferenceToTitleTypeLabel = {
  [ReferenceToTitleType.TORRENS_TITLE]: 'Torrens Title',
  [ReferenceToTitleType.COMPUTER_FOLIO]: 'Computer folio',
  [ReferenceToTitleType.OLD_SYSTEM]: 'Old system',
};

export enum SecurityType {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum SupercontractFormType {
  FULL_FORM = 'FULL_FORM',
  STEP_FORM = 'STEP_FORM',
}
export enum GSTType {
  INCLUSIVE = 'INCLUSIVE',
  EXCLUSIVE = 'EXCLUSIVE',
  N_A = 'N/A',
}
